import React, { useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';

import UserTable from './UserTable';
import UserTableToolbar from './UserTableToolbar';
import { TableViewLayout } from '../../../common/layout/components';

const UserList = () => {
  const [selectedRows, setSelectedRows] = useState<EntityId[]>([]);

  return (
    <>
      <TableViewLayout title="Chauffeurs">
        <UserTableToolbar selectedRows={selectedRows} />
        <UserTable onSelectRows={setSelectedRows} />
      </TableViewLayout>
    </>
  );
};

export default UserList;
