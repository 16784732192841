import React from 'react';

import PlanningTable from './PlanningTable';
import PlanningTableToolbar from './PlanningTableToolbar';
import { TableViewLayout } from '../../common/layout/components';

function PlanningList() {
  return (
    <>
      <TableViewLayout title="Planning">
        <PlanningTableToolbar />
        <PlanningTable />
      </TableViewLayout>
    </>
  );
}

export default PlanningList;
