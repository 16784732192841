import { createAsyncThunk } from '@reduxjs/toolkit';
import { Planning } from '../entities/planning.entity';

interface Options {
	date: string;
}

export const retrievePlanningByDate = createAsyncThunk(
	'[PLANNING] retrievePlanningByDate',
	async (options: Options, thunk: { extra: any }): Promise<Planning[]> => {
		return await thunk.extra.planningService.getByDate(options.date)//);
	}
);
