import React, { useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';

import { Checkbox, Stack, TablePagination } from '@mui/material';
import { Cell, Column, HeaderCell, SortType, Table } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';

import { useElementDimensions } from '../../common/hooks/useElementDimensions';
import { CheckCell, CustomCell } from './';
import { TableEntity } from './Table.entities';
import { customTableFr as locale } from '../i18n';
import './CustomTable.style.css';

function CustomTable(props: TableEntity) {
  const {
    data,
    columns,
    onRowDoubleClick,
    renderActionCell,
    onSelectRows,
    handlePointerEnter,
    handlePointerLeave,
    height,
    width,
    coloredRow,
    ...rest
  } = props;



  const [sortColumn, setSortColumn] = React.useState<string>();
  const [sortType, setSortType] = React.useState<SortType>();
  const [checkedKeys, setCheckedKeys] = useState<EntityId[]>([]);
  const [page, setPage] = React.useState(0);
  const [rect, ref] = useElementDimensions();

  const actionsColumnWidth = rect ? rect.width + 6 : 100;
  const rowsPerPage = 15;

  const getData = () => {
    // sorting

    if (sortColumn && sortType) {
      return (data ?? []).sort((a, b) => {
        let x, y;
        // handling nested props
        if (sortColumn.includes('.')) {
          let split = sortColumn.split('.');
          x = a[split[0]][split[1]];
          y = b[split[0]][split[1]];
        } else {
          x = a[sortColumn];
          y = b[sortColumn];
        }

        if (typeof x === 'string' && typeof y === 'string') {
          if (sortType === 'asc') {
            return x.toUpperCase().localeCompare(y);
          } else {
            return y.toUpperCase().localeCompare(x);
          }
        }

        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }

    return data ?? [];
  };

  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage);

  const handleSortColumn = (sortColumn: string, sortType?: SortType) => {
    setSortColumn(sortColumn);
    setSortType(sortType);
  };

  const handleCheckAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = target;
    const keys = checked ? (data || []).map((item: any) => item.id) : [];
    setCheckedKeys(keys);
    onSelectRows(keys);
  };

  const handleCheck = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = target;
    const keys = checked ? [...checkedKeys, value] : checkedKeys.filter((item) => item !== value);
    setCheckedKeys(keys);
    onSelectRows(keys);
  };

  let isChecked = false;
  let indeterminate = false;

  const dataLength = data?.length || 0;

  if (checkedKeys.length === dataLength) {
    isChecked = true;
  } else if (checkedKeys.length === 0) {
    isChecked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < dataLength) {
    indeterminate = true;
  }

  const onPointerEnter = (event: React.MouseEvent, rowData: object, field: string) => {
    if (!!handlePointerEnter) handlePointerEnter(event, rowData, field);
  };
  const onPointerLeave = (event: React.MouseEvent) => {
    if (!!handlePointerLeave) handlePointerLeave(event);
  };

  return (
    <Stack direction="column">
      <Table
        data={getData().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)} // sort if needed + pagination
        width={width ?? undefined}
        height={height ?? 740}
        rowClassName="active-row"
        className="custom-table"
        affixHeader
        affixHorizontalScrollbar
        locale={locale}
        onSortColumn={handleSortColumn}
        sortColumn={sortColumn}
        sortType={sortType}
        style={{ borderRadius: 8 }}
        {...rest}
      >
        <Column width={50} align="center">
          <HeaderCell style={{ padding: 0 }}>
            <div style={{ lineHeight: '40px' }}>
              <Checkbox checked={isChecked} onChange={handleCheckAll} indeterminate={indeterminate} />
            </div>
          </HeaderCell>
          <CheckCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck} coloredRow={coloredRow} />
        </Column>

        {columns.map((column, index) => {
          const { field, headerName, style, ...otherProps } = column;
          return (
            <Column key={field} {...otherProps}>
              <HeaderCell>{headerName}</HeaderCell>
              <CustomCell
                dataKey={field}
                onDoubleClick={onRowDoubleClick}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                field={field}
                rowIndex={index}
                style={style}
                checkedKeys={checkedKeys}
                // coloredRow = name of the property
                // (example: a row has to be colored when a planning line has a bon, we pass 'hasBon', value has to be boolean)
                coloredRow={coloredRow}
              />
            </Column>
          );
        })}

        {!!renderActionCell && (
          <Column fixed="right" align="center" width={actionsColumnWidth}>
            <HeaderCell className="custom-table-action-header">Actions</HeaderCell>
            <Cell className="custom-table-action-cell">{(rowData) => renderActionCell(rowData, ref)}</Cell>
          </Column>
        )}
      </Table>
      <TablePagination
        component="div"
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]} // empty array to remove options
        count={data?.length ?? 0}
        page={page}
        onPageChange={handleChangePage}
      />
    </Stack>
  );
}

export default CustomTable;
