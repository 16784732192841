import { useLocation, useNavigate } from "react-router-dom";

export function useNavigationFrom(props: { from?: string } = {}) {
    const location = useLocation()
    const navigate = useNavigate()
    const from = props.from || (location.state as any)?.from?.pathname || -1;

    const goBack = () => {
        navigate(from)
    }


    return goBack

}

