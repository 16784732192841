import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { Planning } from '../entities/planning.entity';

export const updateOnePlanning = createAsyncThunk(
	'[PLANNING] updateOnePlanning',
	async (planningDto: Update<Planning>, thunk: { extra: any }): Promise<Update<Planning>> => {
		const { ...planning } = planningDto;
		return await thunk.extra.planningService.update(planning);
	}
);
