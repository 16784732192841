import React from 'react';
import { Container, Box, CssBaseline } from '@mui/material';
import Sidebar from './Sidebar';
import LayoutAlertMessage from './LayoutAlertMessage';

interface Props {
  children?: React.ReactNode;
  title?: string;
}

function Main({ children, title }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <CssBaseline />
      <Sidebar />
      <Container
        component="main"
        maxWidth={false}
        disableGutters
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          px: 1,
        }}
      >
        <LayoutAlertMessage />
        {children}
      </Container>
    </Box>
  );
}

export default Main;
