import React, { useEffect, useState } from 'react';
import { updateSearch } from '../../../core/planning/planning.slice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';

import { Toolbar, TextField, InputAdornment, Divider, useMediaQuery, Theme } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

function PlanningTableToolbar() {
  const [search, setSearch] = useState('');
  const dispatch = useAppDispatch();
  const hasSmallScreen = !useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    dispatch(updateSearch(search));
  }, [dispatch, search]);

  return (
    <>
      <Toolbar sx={{ flexDirection: 'column', gap: 1, alignItems: 'start', mt: 2 }}>
        <TextField
          sx={{ width: hasSmallScreen ? '100%' : 300 }}
          onChange={(event) => {
            const { value } = event.target;
            setSearch(value);
          }}
          label="Rechercher..."
          variant="outlined"
          size={hasSmallScreen ? 'small' : 'medium'}
          InputProps={{
            type: 'search',
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <Divider />
    </>
  );
}

export default React.memo(PlanningTableToolbar);
