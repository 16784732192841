import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Layout } from './common/layout';
import { ROLE } from '../core/auth/role.constant';

interface Props {
  role: string;
}

const ClientProtectedRoute = ({ role }: Props) => {
  const location = useLocation();

  return role === ROLE.CLIENT || role === ROLE.FOURNISSEUR ? <Outlet /> : <Navigate replace to="/planning" state={{ from: location }} />
};

export default ClientProtectedRoute;
