import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLoggedInUser } from '../../../core/auth/selectors';
import { setSuccess } from '../../../core/notification/notification.slice';
import { User } from '../../../core/user/entities/user.entity';
import { retrieveOneUser, updateOneUser, updatePassword } from '../../../core/user/use-cases';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { ViewCard } from '../../common/layout/components';
import PageHeaderBar from '../../common/layout/components/PageHeaderBar';
import { removeEmpty } from '../../common/_helpers';
import { UserAccountUpdateForm } from './UserAccountUpdateForm';

export const UserAccountView = () => {
  const [user, setUser] = useState<Partial<User>>({});
  const [loading, setLoading] = useState(false);
  const { id } = useSelector(selectLoggedInUser);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(retrieveOneUser(id)).then(({ payload }: any) => {
      setUser(payload);
      setLoading(false);
    });

    return () => {
      setLoading(true);
    };
  }, [id, dispatch]);

  const onUpdateUserAccount = async ({
    passwordConfirm,
    password,
    ...values
  }: Partial<User> & { passwordConfirm: string }) => {
    let onlyFilledValues: Partial<User> = removeEmpty(values);
    let hasError = false;

    if (values) {
      const res: any = await dispatch(updateOneUser({ id, changes: onlyFilledValues }));
      if (res.error) hasError = true;
    }

    if (!!password) {
      const resPwChange: any = await dispatch(updatePassword({ id, password }));
      if (resPwChange.error) hasError = true;
    }

    if (!hasError) {
      dispatch(setSuccess(`La mise à jour du compte à bien été effectuée`));
    }
  };

  return (
    <>
      <PageHeaderBar title={'Mettre à jour mon compte'} />
      <ViewCard>
        {loading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 6 }}>
            <CircularProgress />
          </Box>
        ) : (
          <UserAccountUpdateForm initialValues={user} onSubmit={onUpdateUserAccount} />
        )}
      </ViewCard>
    </>
  );
};
