import { RootState } from "../store"

const authSelector = (state: RootState): RootState['auth'] => state.auth

export const selectIsLoggedIn = (state: RootState) => {
    const { isLoggedIn } = authSelector(state);
    return isLoggedIn;
}

export const selectIsLoadingAuth = (state: RootState) => {
    const { loading } = authSelector(state);
    return loading === 'pending';
}

export const selectAuthErrors = (state: RootState) => {
    const { errors } = authSelector(state);
    return errors;
}

export const selectRole = (state: RootState) => {
    const { user } = authSelector(state);
    return (user as any)?.roles?.[0];
}

export const selectEmployeurOfCurrentUser = (state: RootState) => {
    const { user } = authSelector(state);
    return (user as any)?.employeur ?? "";
}

export const selectLoggedInUser = (state: RootState) => {
    const { user } = authSelector(state);
    return user;
}