import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ROLE } from '../core/auth/role.constant';

interface Props {
    role: string;
}

const FournisseurRoute = ({ role }: Props) => {
    const location = useLocation();
    // TODO - change role to fournisseur , accept role Client for testing facility
    return role !== ROLE.FOURNISSEUR ? <Navigate replace to="/planning" state={{ from: location }} /> : <Outlet />;
};

export default FournisseurRoute;
