import React, { useState } from 'react';
import { format, isValid, parse } from 'date-fns';

import { TextField, InputAdornment, IconButton } from '@mui/material';
import { DateRange as DateRangeIcon } from '@mui/icons-material';

import DateRangePopover from './DateRangePopover';

function DateRangeField({ onDateUpdate, ...props }: any) {
	const dateFormat: string = 'dd/MM/yyyy';
	const [ displayDateRange, setDisplayDateRange ] = useState<boolean>(false);
	const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);
	const [ selectedDates, setSelectedDates ] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection'
		}
	]);

	const onAdornmentClick = (event: React.MouseEvent<HTMLElement>) => {
		setDisplayDateRange(true);
		setAnchorEl(event.currentTarget);
	};

	const onPopoverClose = () => {
		setDisplayDateRange(false);
		setAnchorEl(null);
	};

	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		onDateUpdate('date', value);

		const dates = value.split('-');
		if (dates.length > 1) {
			const fromDate = dates[0].trim();
			const toDate = dates[1].trim();

			const startDate = parse(fromDate, dateFormat, new Date());
			const endDate = parse(toDate, dateFormat, new Date());

			if (isValid(startDate) && isValid(endDate)) {
				setSelectedDates([ { startDate, endDate, key: 'selection' } ]);
			}
		}
	};

	const onSelectDate = (values: any) => {
		const { startDate, endDate } = values.selection;
		const localValues = values ? `${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}` : '';

		onDateUpdate('date', localValues);
		setSelectedDates([ { startDate, endDate, key: 'selection' } ]);
		// onPopoverClose();
	};

	return (
		<div>
			<TextField
				{...props}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={onAdornmentClick}>
								<DateRangeIcon />
							</IconButton>
						</InputAdornment>
					)
				}}
				onChange={onInputChange}
			/>
			<DateRangePopover
				onSelectDate={onSelectDate}
				selectedDates={selectedDates}
				onPopoverClose={onPopoverClose}
				displayDateRange={displayDateRange}
				anchorEl={anchorEl}
			/>
		</div>
	);
}

export default DateRangeField;
