import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  IconButton,
  styled,
  Drawer as MuiDrawer,
  List,
  ListItemText,
  ClickAwayListener,
} from '@mui/material';
import {
  Logout,
  Menu as MenuIcon,
  Close as CloseIcon,
  ChevronRight,
  AccountBox,
  AccountCircle,
} from '@mui/icons-material';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { StyledItemIcon, StyledListItemButton } from './StyledListItem';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { logout } from '../../../../core/auth/use-cases';
import { useSelector } from 'react-redux';
import { selectRole } from '../../../../core/auth/selectors';
import SidebarMenuList from './SidebarMenuList';
import SidebarMenuListItem from './SidebarMenuListItem';
// import SidebarMenuList from './SidebarMenuList';

const drawerWidth = 240;
const logo = '/mttp-logo-eee.png';

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.breakpoints.down('sm') ? theme.spacing(0) : theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: any) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const currentRole = useSelector(selectRole);

  const onClickLogout = () => dispatch(logout()).then(() => navigate('/'));

  const handleDrawer = () => setOpen(!open);
  return (
    <>
      {matches && !open ? (
        <Box>
          <IconButton onClick={() => setOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Box>
      ) : (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box sx={{ display: 'flex' }}>
            <Drawer variant="permanent" open={open}>
              <Box
                sx={{
                  height: '100%',
                  background: 'linear-gradient(130deg, rgba(13,109,182,1) 0%, rgba(0,37,65,1) 100%)',
                }}
              >
                {open && (
                  <Box mt={1.5} pr={0.5}>
                    <img alt="logo" src={logo} width="100%" />
                  </Box>
                )}

                {/* Open/Close Button */}
                <DrawerHeader>
                  {!open ? (
                    <IconButton
                      color="inherit"
                      onClick={handleDrawer}
                      edge="start"
                      size="small"
                      sx={{
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        display: open ? 'none' : 'inline',
                        color: '#fff',
                      }}
                    >
                      <ChevronRight />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleDrawer} sx={{ color: '#fff' }}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </DrawerHeader>

                {/* Nav Links */}
                <SidebarMenuList open={open} />
                <Divider />

                <List>
                  {/* Mon compte */}
                  <SidebarMenuListItem href="/mon-compte" open={open} text={'Mon Compte'} icon={<AccountCircle />} />
                  {/* Logout */}
                  <Box sx={{ mx: 1, my: 0.5 }}>
                    <StyledListItemButton onClick={onClickLogout}>
                      <StyledItemIcon>
                        <Logout />
                      </StyledItemIcon>
                      {open && <ListItemText primary="Déconnexion" sx={{ ml: 3 }} />}
                    </StyledListItemButton>
                  </Box>
                </List>
              </Box>
            </Drawer>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
}
