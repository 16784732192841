import { TextField, InputAdornment, Grid, Button } from "@mui/material";
import * as React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import { FormGridItem } from "../../common/form-field";

interface Props {
    bon: Bon,
    onSubmit: onSubmit
    needToBeSign: boolean
    isProcedurePending: boolean
}

interface Bon {
    client: { name: string },
    article: { name: string }
    chauffeur: { name: string }
    chantier: { adresse: { rue: string, cp: string, ville: string }, name: string }
    consignes: { chauffeur: string, evenement: string }
    commande: { quantite: number, unite: string, fraisAutoroute: number }
    procedure?: { immatriculation: string, client?: string, fournisseur?: string }
}
 
type onSubmit = (immatriculation: string, fraisAutoroute?: number, observation?: string,) => void;

function ValidateBeforeSigningForm({ bon, onSubmit, needToBeSign, isProcedurePending }: Props) {
    const [immatriculation, setImmatriculation] = useState<string>('');
    const [fraisAutoroute, setFraisAutoroute] = useState<number>(0);
    const [observation, setObservation] = useState<string>();

    useEffect(() => {
        if (bon?.procedure) setImmatriculation(bon.procedure.immatriculation);
        if (bon?.commande?.fraisAutoroute) setFraisAutoroute(bon.commande.fraisAutoroute);
    }, [bon])

    const addObservation = (event: ChangeEvent<HTMLInputElement>) => { setObservation(event.target.value); };
    const addImmatriculation = (event: ChangeEvent<HTMLInputElement>) => { setImmatriculation(event.target.value); };
    const addFraisAutoroute = (event: ChangeEvent<HTMLInputElement>) => { setFraisAutoroute(+event.target.value); };

    const getBonQuantityOrEmptyString = (): string => bon
        ? `${bon?.commande.quantite} ${bon?.commande.unite}`
        : '';

    // const isProcedurePending = () => !!bon?.procedure?.client && !!bon?.procedure?.fournisseur;

    const onClickConfirm = () => {
        onSubmit(immatriculation, fraisAutoroute, observation);
    }

 
    return <>
        <FormGridItem>
            <TextField fullWidth variant="standard" disabled value={`${bon?.client.name}`} label="Entreprise" />
      
            <TextField fullWidth variant="standard" disabled value={`${bon?.article.name}`} label="Article" />
        </FormGridItem>
        <FormGridItem>
            <TextField
                fullWidth
                variant="standard"
                disabled
                value={`${bon?.chauffeur.name}`}
                label="Chauffeur"
            />
        
            <TextField
                fullWidth
                variant="standard"
                disabled
                value={`${bon?.chantier?.adresse?.rue ?? ''} ` + `${bon?.chantier?.adresse?.cp ?? ''}` + ` ${bon?.chantier?.adresse?.ville ?? ''}` }
                label="Adresse "
            />
        </FormGridItem>
        <FormGridItem>
            <TextField
                fullWidth
                variant="standard"
                disabled
                value={getBonQuantityOrEmptyString()}
                label="Unité"
            />
            <TextField
                fullWidth
                variant="standard"
                disabled
                value={`${bon?.chantier.name}`}
                label="Chantier"
            />
        </FormGridItem>
        <FormGridItem>
            <TextField
                fullWidth
                variant="standard"
                disabled
                multiline
                rows={3}
                value={`${bon?.consignes.chauffeur ?? ''}`}
                label="Consignes"
            />
         
            <TextField
                fullWidth
                variant="standard"
                disabled
                multiline
                rows={3}
                value={`${bon?.consignes.evenement ?? ''}`}
                label="Evenements"
            />
        </FormGridItem>
        <FormGridItem>
            {needToBeSign ? (
                <TextField
                    fullWidth
                    variant="outlined"
                    onChange={addImmatriculation}
                    value={immatriculation}
                    defaultValue={immatriculation}
                    label="N° Immatriculation"
                    disabled={isProcedurePending}
                />
            ) : (
                <TextField fullWidth variant="outlined" disabled value={immatriculation} label="N° Immatriculation" />
            )}
        </FormGridItem>
        <FormGridItem>
            <TextField
                fullWidth
                variant="outlined"
                onChange={addFraisAutoroute}
                label="Frais d'autoroute"
                value={fraisAutoroute}
                defaultValue={fraisAutoroute}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    type: 'number',
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
                disabled={isProcedurePending}
            />
        </FormGridItem>
        <FormGridItem>
            <TextField fullWidth variant="outlined"
                onChange={addObservation}
                label="Observation"
                multiline rows={3}
                disabled={isProcedurePending} />
        </FormGridItem>

        <Grid item display="flex" justifyContent="flex-end" sx={{ my: 2 }} xs={12} gap={3}>
            {/* <Button variant="contained" color="warning" disabled={signed}>
      Modifier
    </Button> */}
            <Button onClick={onClickConfirm} variant="contained" color="primary" disabled={!immatriculation}>
                {!isProcedurePending ? "Confirmer et " : ""}signer
            </Button>
        </Grid>
        {/* </Grid > */}
    </>
}

export default ValidateBeforeSigningForm;