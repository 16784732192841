import React from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';

interface Props {
  id: EntityId;
  measuredRef: any;
}

const UserTableActionsCell = ({ id, measuredRef }: Props) => {
  const navigate = useNavigate();
  const onClickEdit = () => {
    navigate(`./${id}`, { state: id });
  };

  return (
    <Stack direction="row">
      <Box ref={measuredRef}>
        {/* Buttons */}
        <Tooltip title="Editer">
          <IconButton color="primary" size="small" onClick={onClickEdit}>
            <Edit />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Désactiver">
        <IconButton color="error" size="small" onClick={onClickInactive}>
        <PersonOff />
        </IconButton>
      </Tooltip> */}
      </Box>
    </Stack>
  );
};

export default UserTableActionsCell;
