import React from 'react';
import { Checkbox, styled } from '@mui/material';
import { Cell } from 'rsuite-table';

export const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, rowIndex, coloredRow, ...props }: any) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: '46px' }}>
      <Checkbox
        value={rowData[dataKey]}
        onChange={onChange}
        checked={checkedKeys.some((item: any) => item === rowData[dataKey])}
      />
    </div>
  </Cell>
);

export const StyledCell = styled(CheckCell)`
  background-color: ${(props) => {
    if (props.checkedKeys.includes(props.rowData.id)) return '#faf8f4';
    if (!!props.rowData[props.coloredRow]) return '#fff6cc';
    return props.rowIndex % 2 !== 0 ? props.theme.palette.background.default : '';
  }};
`;
