import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { format, startOfMonth, endOfMonth } from "date-fns";
import { BonFilters } from "./entities/bon-filters";
import { BonEntity } from "./entities/bon.entity";
import { retreiveListOfSignableBons } from "./use-cases/retreive-list-of-signable-bons";
import { DATE_FORMAT } from "./constants";


const formattedStartOfMonth = format(startOfMonth(new Date()), DATE_FORMAT);
const formattedEndOfMonth = format(endOfMonth(new Date()), DATE_FORMAT);


export const bonEntityAdapter = createEntityAdapter<BonEntity>({
    selectId: (bon) => bon.id
})

const initialState = bonEntityAdapter.getInitialState({
    filters: {} as BonFilters,
    dates: `${formattedStartOfMonth} - ${formattedEndOfMonth}`,
    createdFromModal: null
});

type BonState = typeof initialState;

export const bonSlice = createSlice({
    name: 'bons',
    initialState,
    reducers: {
        updateFilters: (state: BonState, action: PayloadAction<BonFilters>) => {
            state.filters = action.payload;
        },
        updateDates: (state: BonState, action: PayloadAction<string>) => {
            state.dates = action.payload;
        },
        updateCreatedFromModal: (state: BonState, action: PayloadAction<any>) => {
            state.createdFromModal = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(retreiveListOfSignableBons.fulfilled, (state, action) => {
                bonEntityAdapter.setAll(state, action.payload);
            })
    }
})

export const { updateDates, updateFilters, updateCreatedFromModal } = bonSlice.actions;
export default bonSlice.reducer;