import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { Planning as PlanningEntity } from './entities/planning.entity';
import {
	retrieveAllPlanning,

	updateOnePlanning,
	retrievePlanningByDate
} from './use-cases';

export const planningEntityAdapter = createEntityAdapter<PlanningEntity>({
	selectId: (planning) => planning.id
});

export const planningSlice = createSlice({
	name: 'planning',
	initialState: planningEntityAdapter.getInitialState({ search: '', date: format(new Date(), 'dd/MM/yyyy') }),
	reducers: {
		updateSearch: (state, action) => {
			state.search = action.payload;
		},
		updateDate: (state, action) => {
			state.date = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(retrieveAllPlanning.fulfilled, (state, action) => {
				planningEntityAdapter.setAll(state, action.payload);
			})
			.addCase(retrievePlanningByDate.fulfilled, (state, action) => {
				planningEntityAdapter.setAll(state, action.payload);
			})
			.addCase(updateOnePlanning.fulfilled, (state, action) => {
				planningEntityAdapter.updateOne(state, action.payload);
			});
	}
});

export const { updateSearch, updateDate } = planningSlice.actions;
export default planningSlice.reducer;
