import { createAsyncThunk, Update } from '@reduxjs/toolkit';
import { User } from '../entities/user.entity';

export const updateOneUser = createAsyncThunk(
	'[USER] updateOneUser',
	async (userDto: Update<User>, thunk: { extra: any }): Promise<Update<User>> => {
		const { ...user } = userDto;
		return await thunk.extra.userQuery.update(user);
	}
);
