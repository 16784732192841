import { createAsyncThunk } from "@reduxjs/toolkit";

import { User } from "../entities/user.entity";

export const addOneUser = createAsyncThunk(
    "[USER] addOne",
    async (user: Partial<User>, thunk: { dispatch: any, rejectWithValue: any, extra: any }) => {
        try {
            return await thunk.extra.userQuery.add(user);
        } catch (error) {
            //console.log(error);
        }
    }
)