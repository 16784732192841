import React from 'react';
import { styled } from '@mui/material';
import { Cell } from 'rsuite-table';
// import { StyledCell } from './StyledCell';

export const CustomCell = React.forwardRef((props: any, ref: any) => {
  const {
    children,
    rowData,
    field,
    onDoubleClick,
    onPointerEnter,
    onPointerLeave,
    rowIndex,
    checkedKeys,
    coloredRow,
    ...rest
  } = props;

  return (
    <Cell
      ref={ref}
      rowData={rowData}
      onDoubleClick={() => onDoubleClick(rowData)}
      onPointerEnter={(event) => {
        onPointerEnter(event, rowData, field);
      }}
      onPointerLeave={(event) => {
        onPointerLeave(event);
      }}
      {...rest}
    >
      {children}
    </Cell>
  );
});

export const StyledCell = styled(CustomCell)`
  background-color: ${(props) => {
    if (props.checkedKeys.includes(props.rowData.id)) return '#faf8f4';
    if (!!props.rowData[props.coloredRow]) return '#fff6cc';
    return props.rowIndex % 2 !== 0 ? props.theme.palette.background.default : '';
  }};
`;
