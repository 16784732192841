import React from 'react';
import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { object, string, setLocale } from 'yup';

import { Box, Grid, MenuItem, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Business, Email, LockOpen, PermIdentity, Phone, VpnKey } from '@mui/icons-material';

import { User } from '../../../../core/user/entities/user.entity';
// import { selectAllLabelsAndIds } from '../../../../core/entreprise/selectors';
import { FormFieldAutocomplete, FormFieldLayout, FormFieldModalLink } from '../../../common/form-field';
import FormFieldAutocompleteContext from '../../../common/form-field/FormFieldAutocompleteContext';
import { FormGridItem } from '../../../common/form-field/FormGridItem';
import { roles } from '../../../common/roles.constant';
import { yupFr } from '../../../common/i18n';
import { selectEmployeurOfCurrentUser } from '../../../../core/auth/selectors';

interface Props {
  initialValues: Partial<User>;
  onSubmit(values: Partial<User>): void;
  isEditing: boolean;
  defaultValues?: any;
}

function UserEditionForm({ initialValues, onSubmit, isEditing, defaultValues }: Props) {
  const employeurOfCurrentUser = useSelector(selectEmployeurOfCurrentUser);
  // const entreprises: any = useSelector(selectAllLabelsAndIds);

  // Validation schema: IF the selected role is admin, then 'employeur', 'lastname', 'phone' are not required
  //                    IF we are updating a user, then password is not shown
  setLocale(yupFr);
  const schema = object().shape({
    name: string().required(),
    email: string().email().required(),
    password: isEditing ? string().nullable().notRequired() : string().min(8).required(),
    roles: string().required(),
    employeur: string()
      .nullable(),
      // .notRequired()
      // .when('roles', (role, schema) => (role !== 'admin' ? schema.required() : schema)),
    lastname: string()
      .nullable(),
      // .notRequired()
      // .when('roles', (role, schema) => (role !== 'admin' ? schema.required() : schema)),
    telephone: string()
      .nullable()
    // .notRequired()
    // .when('roles', (role, schema) => (role !== 'admin' ? schema.required() : schema)),
  });

  const _displayNonAdminFields = (role: any) => {
    if (role === 'admin') {
      return role === 'admin' ? 'none' : 'inline';
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        } = props;
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <Grid container direction="row" spacing={4} p={4} width={{ xs: '100%', md: '75%', xl: '60%' }}>

                <Grid item xs={12}>
                  <Stack direction="row" gap={2}>
                    <PermIdentity sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Prénom"
                      error={!!errors.name && !!touched.name}
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      required
                    />
                    <TextField
                      fullWidth
                      label="Nom"
                      error={!!errors.lastname && !!touched.lastname}
                      name="lastname"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.lastname && errors.lastname}
                      variant="outlined"
                      required={values.roles !== 'admin'}
                      sx={{ display: _displayNonAdminFields(values.roles) }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} display={isEditing ? 'none' : 'inline'}>
                  <Stack direction="row" gap={2}>
                    <VpnKey sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Mot de passe"
                      type="password"
                      name="password"
                      error={!!errors.password && !!touched.password}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.password && errors.password}
                      variant="outlined"
                      required={!isEditing}
                    />
                  </Stack>
                </Grid>

                <FormGridItem>
                  <Email sx={{ mt: 2 }} />
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    error={!!errors.email && !!touched.email}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.email && errors.email}
                    variant="outlined"
                    required
                  />
                </FormGridItem>
                <Grid item xs={12} display={_displayNonAdminFields(values.roles)}>
                  <Stack direction="row" gap={2}>
                    <Phone sx={{ mt: 2 }} />
                    <TextField
                      fullWidth
                      label="Téléphone"
                      name="telephone"
                      error={!!errors.telephone && !!touched.telephone}
                      value={values.telephone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.telephone && errors.telephone}
                      variant="outlined"
                      required={values.roles !== 'admin'}
                    />
                  </Stack>
                </Grid>
                <Grid item display="flex" justifyContent="flex-end" sx={{ my: 2 }} xs={12}>
                  <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={!dirty || !isValid}>
                    Enregistrer
                  </LoadingButton>
                </Grid>
              </Grid>
              <FormFieldAutocompleteContext />
            </Form>
          </>
        );
      }}
    </Formik >
  );
}

export default UserEditionForm;
