import { useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { setSuccess } from '../../../core/notification/notification.slice';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { TableViewLayout } from '../../common/layout/components';
import BonTable from './BonTable';
import BonTableToolbar from './BonTableToolbar';

// import { archiveBons } from '../../../../core/bon/use-cases';
// import { useAppDispatch } from '../../../common/hooks/useAppDispatch';

// import BonTableToolbar from './BonTableToolbar';
// import BonTable from './BonTable';
// import ConfirmationDialog from '../../../common/modals/components/ConfirmationDialog';
// import { setSuccess } from '../../../../core/notification/notification.slice';
// import { TableViewLayout } from '../../../common/layout/components';

function BonList() {
  const dispatch = useAppDispatch();

  const [selectedRows, setSelectedRows] = useState<EntityId[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isConfirmDeleteButtonLoading, setIsConfirmDeleteButtonLoading] = useState(false);

  const handleDialog = () => setIsDialogOpen(!isDialogOpen);

  const onConfirmDialog = async () => {
    //   setIsConfirmDeleteButtonLoading(true);

    //   // const res: any = await dispatch(archiveBons(selectedRows));

    //   if (!res.error) {
    //     setIsDialogOpen(false);
    //     dispatch(setSuccess(`Les données ont bien été archivées`));
    //   }

    //   setIsConfirmDeleteButtonLoading(false);
  };

  return (
    <>
      <TableViewLayout title="Bons">
        <BonTableToolbar onClickArchiveMultiple={handleDialog} selectedRows={selectedRows} />
        <BonTable onSelectRows={setSelectedRows} />
      </TableViewLayout>
      {/* <ConfirmationDialog
        open={isDialogOpen}
        title="Archiver"
        contentText={`Êtes-vous sûr de vouloir archiver ces données ?`}
        isButtonLoading={isConfirmDeleteButtonLoading}
        onClose={handleDialog}
        onConfirm={onConfirmDialog}
      /> */}
    </>
  );
}

export default BonList;
