import { createAsyncThunk, EntityId } from '@reduxjs/toolkit';
import { User } from '../entities/user.entity';

export const retrieveOneUser = createAsyncThunk(
	'[USER] retrieveOneUser',
	async (id: EntityId, thunk: { extra: any, rejectWithValue: any }): Promise<User> => {
		try {
			return await thunk.extra.userQuery.getOne(id);
		} catch (error: any) {
			return thunk.rejectWithValue({ message: error.message ?? null });
		}
	}
);