import { createAsyncThunk, EntityId } from "@reduxjs/toolkit";

interface Options {
  id: EntityId;
  password: string;
}

export const updatePassword = createAsyncThunk(
    "[USER] updatePassword",
    async (options: Options, thunk: { extra: any }) => {
        return await thunk.extra.userQuery.updatePassword(options);
    }
)