import React, { useEffect, useRef } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoute from './app/AppRoute';
import { ThemeProvider } from '@mui/material';
import theme from './theme';

function App() {
  useDocumentTitle(`MTTP`);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <AppRoute />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

function useDocumentTitle(title:string, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}

export default App;
