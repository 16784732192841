/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEvent, useEffect, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { Stack } from '@mui/material';
import { RootState } from '../../../core/store';
import { CustomTableToolbar } from '../../common/custom-table';
import { DateRangeField } from '../../common/date-picker';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { updateDates } from '../../../core/bon/bon.slice';


interface Props {
  onClickArchiveMultiple(event: React.MouseEvent): void;
  selectedRows: EntityId[];
}

function BonTableToolbar({ onClickArchiveMultiple, selectedRows }: Props) {
  const selectedDates = useSelector((state: RootState) => state.bon.dates);
  // const filters: any = useSelector((state: RootState) => state.bon.filters);

  // const [isSearchPopoverOpen, setIsSearchPopoverOpen] = useState(false);
  // const [isEditionDialogOpen, setIsEditionDialogOpen] = useState(false);
  // const [filtersBadge, setFiltersBadge] = useState(0);
  // const [searchAnchorEl, setSearchAnchorEl] = useState<Element | null>(null);

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   let badgeCounter = 0;
  //   let property: string;

  //   // for (property in filters) {
  //   //   if (filters[property] !== '') {
  //   //     badgeCounter++;
  //   //   }
  //   }

  //   setFiltersBadge(badgeCounter);
  // }, [filters]);

  // const onOpenSearchPopover = (event: MouseEvent) => {
  //   const { currentTarget } = event;
  //   setIsSearchPopoverOpen(true);
  //   setSearchAnchorEl(currentTarget);
  // };

  // const onCloseSearchPopover = () => {
  //   setIsSearchPopoverOpen(false);
  //   setSearchAnchorEl(null);
  // };

  const onDateUpdate = (field: string, value: string) => {
    if (value !== selectedDates) dispatch(updateDates(value));
  };

  // const onHandleEditionDialog = () => setIsEditionDialogOpen(!isEditionDialogOpen);

  return (
    <>
      <CustomTableToolbar>
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <DateRangeField
            sx={{ width: 270 }}
            size="small"
            variant="outlined"
            label="Filtrer par date..."
            onDateUpdate={onDateUpdate}
            value={selectedDates}
          />

          {/* Buttons */}
          {/* <Tooltip title="Filtrer">
            <IconButton onClick={onOpenSearchPopover}>
              <Badge badgeContent={filtersBadge} color="error">
                <TuneIcon />
              </Badge>
            </IconButton>
          </Tooltip>

          <Button
            variant="contained"
            size="small"
            color="secondary"
            disabled={selectedRows.length < 1}
            onClick={onHandleEditionDialog}
            startIcon={<Receipt />}
          >
            Ajouter Ref. Client et N° Bon
          </Button>

          <Button
            variant="contained"
            size="small"
            color="warning"
            disabled={selectedRows.length < 1}
            onClick={onClickArchiveMultiple}
            startIcon={<ArchiveIcon />}
          >
            Archiver
          </Button> */}
        </Stack>
        {/* <Stack direction="row" spacing={4} alignItems="end">
          <Button component={Link} to="create" variant="contained" color="secondary" startIcon={<Add />}>
            Nouveau Bon
          </Button>
        </Stack> */}
      </CustomTableToolbar>

      {/* Popover & Dialogs */}
      {/* <BonEditionDialog open={isEditionDialogOpen} ids={selectedRows} onClose={onHandleEditionDialog} /> */}

      {/* <BonSearchPopover 
       open={isSearchPopoverOpen}
        anchorEl={searchAnchorEl}
        onCloseSearchPopover={onCloseSearchPopover}
      /> */}
    </>
  );
}

export default BonTableToolbar;
