import { isValid, parse } from "date-fns";

//  function was called validateDates Before Refactoring
export const formatRangeDatesStringToFromToObject = (selectedDates: string) => {
  if (selectedDates === '') {
    return { fromDate: '', toDate: '' };
  }
  const [fromDate, toDate] = selectedDates.split('-');
  return (isValidateFrDateString(fromDate.trim()) && isValidateFrDateString(toDate.trim()))
    ? { fromDate: fromDate.trim(), toDate: toDate.trim() }
    : null
}

export const isValidateFrDateString = (date: string): boolean => {
  const DATE_FORMAT = "dd/MM/yyyy";
  return isValid(parse(date, DATE_FORMAT, new Date()));
}

export const removeEmpty = (obj: object): object => {
  return Object.fromEntries(
    Object.entries(obj)
      .map(([k, v]) => {
        return [k, v === Object(v) ? removeEmpty(v) : v]
      })
      .filter(([_, v]) => (!!v && typeof v !== 'object') || (Object(v) && !!Object.keys(v).length))
  );
}