import { createAsyncThunk } from "@reduxjs/toolkit";
import { ROLES } from "../../roles.enum";
// import { useCases as chantierUseCases } from "../../chantier";
// import { useCases as entrepriseUseCases } from "../../entreprise";
// import { useCases as interlocuteurUseCases } from "../../interlocuteur";
// import { useCases as articleUsesCases } from "../../article";

export interface AuthenticateQuery {
    (token: string): Promise<any>
}

interface AuthenticateQueryResult {
    access: { token: string, exp: number }
    refresh: { token: string, exp: number }
    user: { id: string, name: string, email: string, roles: string[] }
}

interface ThunkExtraArgs {
    authService: { authenticate: AuthenticateQuery },
    // entrepriseQuery: any,
    // chantierQuery: any,
    // interlocuteurQuery: any,
    // articleQuery: any
}

export const authenticateUser = createAsyncThunk<
    AuthenticateQueryResult,
    string,
    { extra: ThunkExtraArgs }
>(
    "[AUTH] authenticateUser",
    async (refreshToken, thunk) => {

        const userAuthenticated = await thunk.extra.authService.authenticate(refreshToken);
        if (userAuthenticated.user.roles.includes(ROLES.ADMIN)) { throw new Error('Cette application n\'est pas encore disponible pour les administrateurs') }

        // thunk.dispatch(entrepriseUseCases.retrieveAllEntreprises());
        // thunk.dispatch(chantierUseCases.retrieveAllChantiers());
        // thunk.dispatch(interlocuteurUseCases.retreiveAllInterlocuteurs());
        // thunk.dispatch(articleUsesCases.retreiveAllArticles());
        return userAuthenticated;
    });