import { createAsyncThunk } from "@reduxjs/toolkit"
import tokenStorageService from "../../../infra/storage-provider/token-storage.service"
import { authSlice } from "../slice"

export const logout = createAsyncThunk("[AUTH] Logout",
    async (_, thunk) => {
        //console.log("LOGOUT FROM ASYNC THUNK")
        tokenStorageService.clear().all();
        thunk.dispatch(authSlice.actions.logout())
    }
)     