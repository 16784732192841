/**
 * Redux-store
 */
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth';
import userReducer from './user';
import planningReducer from './planning';
import notificationReducer from './notification';
import bonReducer from './bon';
// import entrepriseReducer from './entreprise';
// import interlocuteurReducer from './interlocuteur';
// import articleReducer from './article';
// import bonReducer from './bon';
// import { chantierSlice } from './chantier/chantier.slice';

import * as AuthApi from '../infra/api/auth.api-service';
import * as PlanningApi from "../infra/api/planning.api.service";
import * as BonApi from "../infra/api/bon.api.service";
import * as UserApi from "../infra/api/user.api-service";

const rootReducer = combineReducers({
	auth: authReducer,
	user: userReducer,
	planning: planningReducer,
	bon: bonReducer,
	notifications: notificationReducer,


});

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: {
				extraArgument: {
					authService: AuthApi,
					planningService: PlanningApi,
					bonService: BonApi,
					userQuery: UserApi
				}
			}
		}),

	devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
