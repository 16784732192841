import React from 'react';
import { List } from '@mui/material';
import {
  SvgIconComponent,
  DateRange,
  ContactMail,
  Description,
  Receipt,
  Unarchive,
  MiscellaneousServices,
  Business,
} from '@mui/icons-material';
import MenuListItem from './SidebarMenuListItem';
import { useSelector } from 'react-redux';
import { selectRole } from '../../../../core/auth/selectors';
import { ROLE } from '../../../../core/auth/role.constant';

export interface SidebarMenuItemProps {
  text: string;
  icon: React.ReactElement<SvgIconComponent>;
  href: string;
  permissions: string[];
}

const menuItems: SidebarMenuItemProps[] = [
  {
    text: 'Planning',
    icon: <DateRange />,
    href: '/planning',
    permissions: [ROLE.CHAUFFEUR],
  },
  {
    text: 'Bons',
    icon: <Receipt />,
    href: '/bons',
    permissions: [ROLE.CLIENT, ROLE.FOURNISSEUR],
  },
  // {
  //   text: 'Factures',
  //   icon: <Description />,
  //   href: '/factures',
  //   permissions: []
  // },
  // {
  //   text: 'Entreprises',
  //   icon: <Business />,
  //   href: '/entreprises',
  //   permissions: []
  // },
  {
    text: 'Utilisateurs',
    icon: <ContactMail />,
    href: '/user',
    permissions: [ROLE.FOURNISSEUR], // {TODO} ROLE CLIENT TO BE REMOVED BEFORE DEPOY IN PROD
  },
  // {
  //   text: 'Articles',
  //   icon: <Unarchive />,
  //   href: '/articles',
  //   permissions: []
  // },
  // {
  //   text: 'Paramètres',
  //   icon: <MiscellaneousServices />,
  //   href: '/parametres',
  //   permissions: ["*"]
  // },
];

function SidebarMenuList({ open }: any) {
  const currentRole = useSelector(selectRole);

  return (
    <List>
      {menuItems.map((item) => {
        return item.permissions.includes(currentRole) || item.permissions.includes('*') ? (
          <MenuListItem text={item.text} icon={item.icon} href={item.href} key={item.text} open={open} />
        ) : null;
      })}
    </List>
  );
}

export default SidebarMenuList;
