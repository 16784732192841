import httpApiAdapter, { _apiUrlFactory } from "../api-provider";
import { User as UserEntity } from "../../core/user/entities/user.entity";
import { EntityId, Update } from "@reduxjs/toolkit";

const httpProvider = httpApiAdapter();

const prefix = 'user'
const apiUserUrl = _apiUrlFactory(prefix);

export const getAll = async () => {
  const users = await httpProvider.get(apiUserUrl() + "?role=chauffeur")
  return users.map((user: any) => _mapToEntity(user))
}

export const getOne = async (id: EntityId) => {
  return await httpProvider.get(apiUserUrl(`${id}`));
}

export const add = async (user: UserEntity): Promise<Partial<UserEntity>> => {
  return await httpProvider.post(apiUserUrl(), user);
}

export const resetPassword = async (email: object) => {
  return await httpProvider.post(apiUserUrl('reset-password'), email);
}

export const resetPasswordConfirm = async ({ token, password }: { token: string; password: string }) => {
  return await httpProvider.post(`${apiUserUrl('reset-password-confirm')}?reset-token=${token}`, { 'reset-password': password });
}

export const update = async (user: Update<UserEntity>): Promise<UserEntity> => {
  return await httpProvider.put(apiUserUrl(`${user?.id}`), user.changes);
};

export const updatePassword = async ({ id, password }: { id: string; password: string }) => {
  return await httpProvider.patch(`${apiUserUrl(`${id}`)}/refresh-pwd`, { password });
}

export const deactivate = async (options: { ids: EntityId }) => {
  return await httpProvider.post(apiUserUrl('deactivate'), options);
}

export const reactivate = async (options: { ids: EntityId }) => {
  return httpProvider.post(apiUserUrl('reactivate'), options);
}


const _mapToEntity = (user: any) => {
  const { roles, ...otherProps } = user;
  return { roles: Array.isArray(roles) ? roles.join() : roles, ...otherProps }
}