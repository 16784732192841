import { Tooltip } from '@mui/material';
import { AccessTime, Announcement, Archive, Cancel, Done, Timelapse } from '@mui/icons-material';

type StatusIcon = {
  tooltip: string;
  icon: JSX.Element;
};

export enum Status {
  DRAFT,
  CREATED,
  PENDING,
  REJECTED,
  SIGNED,
  ARCHIVED,
}

interface Props {
  param: Status;
}

export const BonTableStatusCell = ({ param }: Props) => {
  const status: { [key in keyof typeof Status]: StatusIcon } = {
    DRAFT: {
      tooltip: 'Nouveau bon',
      icon: <Announcement color="secondary" />,
    },
    CREATED: {
      tooltip: 'En attente des signatures',
      icon: <AccessTime color="warning" />,
    },
    PENDING: {
      tooltip: 'Une signature manquante',
      icon: <Timelapse color="warning" />,
    },
    REJECTED: {
      tooltip: 'Bon rejeté',
      icon: <Cancel color="error" />,
    },
    SIGNED: {
      tooltip: 'Bon signé',
      icon: <Done color="success" />,
    },
    ARCHIVED: {
      tooltip: 'Bon archivé',
      icon: <Archive color="info" />,
    },
  };

  return <Tooltip title={status[param].tooltip}>{status[param].icon}</Tooltip>;
};
