import React from 'react';
import { Popover } from '@mui/material';
import { DateRange, Range } from 'react-date-range';
import { fr } from 'date-fns/locale';

interface DateRangePopoverProps {
  onSelectDate(values: Range): void;
  onPopoverClose(): void;
  displayDateRange: boolean;
  anchorEl: HTMLElement | null;
  selectedDates: Range[];
}

function DateRangePopover({
  onSelectDate,
  onPopoverClose,
  displayDateRange,
  anchorEl,
  selectedDates,
}: DateRangePopoverProps) {
  return (
    <Popover
      open={displayDateRange}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onPopoverClose}
    >
      <DateRange
        ranges={selectedDates}
        onChange={onSelectDate}
        locale={fr}
        moveRangeOnFirstSelection={false}
        editableDateInputs={false}
        showDateDisplay={false}
      />
    </Popover>
  );
}

export default DateRangePopover;
