export const retreiveFromStorage = (item: string) => {
  return localStorage.getItem(item);
};

export const get = (item: string) => {
  return localStorage.getItem(item);
};

export const saveInStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const removeFromStorage = (key: string) => {
  localStorage.removeItem(key);
};
