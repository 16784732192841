import { BonEntity } from "../../core/bon/entities/bon.entity";

type BonQueryDateType = | "fromDate" | "toDate";
type BonQueryArgsType = | "filters" | "fromDate" | "toDate" | 'query'

export interface GetArgs {
    filters: BonFilters;
    fromDate: string;
    toDate: string;
    query?: string;
}

type BonFiltersType =
    | "fournisseur"
    | "chantier"
    | "client"
    | "interlocuteurClient"
    | "article"
    | "status"

type BonFilters = {
    [key in BonFiltersType]?: string | string[];
};

export const _getQueryParamsToUrl = ({ filters, fromDate, toDate }: GetArgs) => {
    const _querySearchParamUrl = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
        const currentFilter = filters[key as unknown as BonFiltersType];
        if (currentFilter && currentFilter.length) {
            Array.isArray(currentFilter) ?
                currentFilter.forEach(id => _querySearchParamUrl.append(key, id))
                : _querySearchParamUrl.append(key, currentFilter);
        }
    })
    if (fromDate) _querySearchParamUrl.append('from', fromDate);
    if (toDate) _querySearchParamUrl.append('to', toDate);

    const urlSearchParamsHasAtLeastOneParam = _querySearchParamUrl.toString().length > 0;
    return urlSearchParamsHasAtLeastOneParam ? `?${_querySearchParamUrl.toString()}` : '';
}


export const _mapBonApiToEntity = (bon: BonApi): BonEntity => {
    return {
        id: bon.id,
        client: bon.client?.name,
        chantier: bon.chantier?.name,
        commandePar: `${bon.interlocuteurClient?.name} ${bon.interlocuteurClient?.lastname}`,
        article: bon.article?.name,
        fournisseur: bon.fournisseur?.name,
        chauffeur: bon.chauffeur?.name,
        unite: bon.commande?.unite,
        quantite: bon.commande?.quantite,
        prixAchatHT: bon.commande?.prixAchatHT,
        prixVenteHT: bon.commande?.prixVenteHT,
        achatHT: bon.commande?.marge,
        montantHT: bon.commande?.montantHT,
        date: bon.expectedDateOfPrestation,
        bonNumber: bon.bonNumber,
        referenceClient: bon.referenceClient,
        status: bon.status,
        docs: bon.docs,
        fraisAutoroute: bon.commande?.fraisAutoroute,
        commentaire_interne: bon.consignes?.mttp,
        consignes_chauffeur: bon.consignes?.chauffeur,
        evenement: bon.consignes?.evenement,
        procedure: bon.procedure,
        ids: {
            client: bon.client?.id,
            commandePar: bon.interlocuteurClient?.id,
            chauffeur: bon.chauffeur?.id,
            fournisseur: bon.fournisseur?.id,
            article: bon.article?.id,
            chantier: bon.chantier?.id,
        }
    };
};


interface NestedObject {
    _id: string;
    name: string;
    id: string;
    lastname?: string;
}

export interface BonApi {
    _id: string;
    article: NestedObject;
    chantier: NestedObject;
    client: NestedObject;
    fournisseur: NestedObject;
    interlocuteurClient: NestedObject;
    chauffeur?: NestedObject;
    commande: {
        _id: string;
        marge: number;
        montantHT: number;
        prixAchatHT: number;
        prixVenteHT: number;
        quantite: number;
        unite: string;
        fraisAutoroute: number;
    };
    docs: { _id: string; mimetype: string; originalname: string }[];
    procedure: { observations: { client: string; fournisseur: string } };
    expectedDateOfPrestation: string;
    id: string;
    status: string;
    bonNumber: string;
    referenceClient: string;
    consignes: {
        mttp: string;
        chauffeur: string;
        evenement: string;
    };
}