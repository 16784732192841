import { createAsyncThunk } from "@reduxjs/toolkit";

interface Options {
  token: string;
  password: string;
}

export const resetPasswordConfirm = createAsyncThunk(
    "[USER] resetPasswordConfirm",
    async (options: Options, thunk: { extra: any }) => {
        return await thunk.extra.userQuery.resetPasswordConfirm(options);
    }
)