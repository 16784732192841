import { Alert } from '@mui/material';

interface Props {
  fournisseurConfirmed: boolean
  status: string
  isProcedureStarted: boolean
}

function BonSignatureAlertStatus({ status, fournisseurConfirmed, isProcedureStarted }: Props) {

  const hasBeenProcessed = () => !!status && !['DRAFT', 'CREATED'].includes(status);

  if (/*!fournisseurConfirmed && */ hasBeenProcessed())
    return (<Alert color="info" variant="filled">
      Ce bon est en attente ou a été refusé
    </Alert>);

  if (fournisseurConfirmed)
    return (<Alert color="warning" variant="filled">
      Vous avez déjà signé ce bon
    </Alert>);

  if (isProcedureStarted)
    return (<Alert color="warning" variant="filled">
      Ce bon est en attente d'être signé
    </Alert>);



  return null;
}


export default BonSignatureAlertStatus;