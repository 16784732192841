import { planningEntityAdapter } from './planning.slice';
import { RootState } from '../store';
import { EntityId } from '@reduxjs/toolkit';

const planningSelector = planningEntityAdapter.getSelectors<RootState>((state) => state.planning);

export const selectAllPlanning = (state: RootState) => {
	const planning = planningSelector.selectAll(state);
	return planning;
};

export const selectPlanningById = (id: EntityId) => (state: RootState) => planningSelector.selectById(state, id);

export const selectPlanningBySearch = (state: RootState) => {
	const propertiesToFilter = ['client', 'chantier', 'commandePar', 'article', 'fournisseur'];
	const plannings = selectAllPlanning(state);
	const { search } = state.planning;

	return plannings.filter((planning: any) => {
		return Object.keys(planning).some((property) => {
			return propertiesToFilter.includes(property)
				? planning?.[property]?.toLowerCase().includes(search.toLowerCase())
				: false;
		});
	});
};
